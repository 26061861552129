import React, { useEffect, useState } from "react"
import { Container, Col, Row } from "reactstrap"
import styled from "styled-components"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { Box, Heading, Paragraph } from "grommet"

let StyledFeature = styled(Link)`
  text-text-decoration: none;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  transition-duration: 0.25s;

  &:hover {
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
    text-decoration: none;
  }
`

let Feature = ({ title, description, img, slug }) => (
  <Col md={4} className="mb-3">
    <StyledFeature to={slug}>
      <Img fluid={img} />
      <Box
        margin={{
          vertical: "medium",
        }}
      >
        <Heading as="h5" size="xxsmall">
          {title}
        </Heading>
        <Paragraph color="#212529">{description}</Paragraph>
      </Box>
    </StyledFeature>
  </Col>
)

const AllCourses = ({ courses }) => {
  return (
    <Container className="py-5">
      <Row>
        {courses?.map((course, i) => (
          <Feature
            title={course.node.title}
            description={course.node.metaDescription}
            img={course.node.coverPhoto?.fluid}
            key={i}
            slug={`/courses/${course.node.slug}`}
          />
        ))}
      </Row>
    </Container>
  )
}

export default AllCourses
