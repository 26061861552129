import React from "react"
import { graphql } from "gatsby"
import { Container } from "reactstrap"
import SEO from "../components/seo"
import Slider from "../components/slider-courses"
import AllCourses from "../components/cards/all-courses"
// import Testimonials from "../components/testimonials"
import TitleBanner from "../components/titleBanner/TitleBanner"
import CardsList from "../components/common/cardsList/CardsList"
import Reviews from "../components/product-detail/itemReviews/Reviews"

let CoursesAWS = ({ data }) => {
  const { awsCourses } = data
  return (
    <>
      <SEO
        title="Amazon Web Services (AWS)"
        description="Discover the value of building, deploying, and operating applications on the AWS Cloud"
      />

      <TitleBanner
        title="Amazon Web Services"
        description="Discover the value of building, deploying, and operating applications on the AWS Cloud"
        withOnlyDescription
      />

      <div className="container">
        <CardsList
          courses={awsCourses.nodes}
          withoutFilter
        />
        {/* <div className="reviews-cover">
          <Reviews />
        </div> */}
      </div>
    </>
  )
}

export const awsQuery = graphql`
  query allAWSQuery {
    awsCourses: allContentfulTraining(
      filter: { category: { eq: "aws" } }
    ) {
      nodes {
        id
        title
        category
        courseReference
        trainingMode
        updatedAt
        slug
        skillsFramework
        requirements
        lessons
        product {
          id
          name
          netFee
          price
        }
        duration
        coverPhoto {
          gatsbyImageData
        }
        courseRuns
      }
    }
  }
`

export default CoursesAWS
